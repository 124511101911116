import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarLink from '../Sidebar/SidebarLink';
import {
  PAGE_EMAIL_MANAGER,
  PAGE_MEDIA_LIBRARY,
  PAGE_MENUS,
  PAGE_PROMOTIONS,
  PAGE_SETTINGS
} from '../../constants/UriConstants';

interface MobileBurgerSideBarProps {
  isVisible: boolean;
  onClose: () => void;
}

const MobileBurgerSideBar = ({ isVisible, onClose }: MobileBurgerSideBarProps) => {
  const { pathname } = useLocation();
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  const handleSectionClick = () => {
    onClose();
    return false;
  };

  const handleActive = (path: string) =>
    pathname === path ||
    pathname.startsWith(`${path}/`) ||
    (pathname === '/' && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-menu') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-menu-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('create-drink-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-menu') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-menu-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-drink-item') && path === `/${PAGE_MENUS}`) ||
    (pathname.includes('edit-restaurant') && path === `/${PAGE_SETTINGS}`);

  const isMenuActive = handleActive(`/${PAGE_MENUS}`);
  const isPromotionsActive = handleActive(`/${PAGE_PROMOTIONS}`);
  const isEmailActive = handleActive(`/${PAGE_EMAIL_MANAGER}`);
  const isSettingsActive = handleActive(`/${PAGE_SETTINGS}`);
  const isMediaActive = handleActive(`/${PAGE_MEDIA_LIBRARY}`);
  return (
    <div ref={sidebarRef} className={`mobile-burger-sidebar ${isVisible ? 'visible' : ''}`}>
      <ul className="sidebar-link-container">
        <SidebarLink name="Menu" path={`/${PAGE_MENUS}`} isActive={isMenuActive} onClick={handleSectionClick} />
        <SidebarLink
          name="Promotions"
          path={`/${PAGE_PROMOTIONS}`}
          isActive={isPromotionsActive}
          onClick={handleSectionClick}
        />
        <SidebarLink
          name="Emails"
          path={`/${PAGE_EMAIL_MANAGER}`}
          isActive={isEmailActive}
          onClick={handleSectionClick}
        />
        <SidebarLink
          name="Media"
          path={`/${PAGE_MEDIA_LIBRARY}`}
          isActive={isSettingsActive}
          onClick={handleSectionClick}
        />
        <SidebarLink
          name="Profile & Settings"
          path={`/${PAGE_SETTINGS}`}
          isActive={isMediaActive}
          onClick={handleSectionClick}
        />
      </ul>
    </div>
  );
};

export default MobileBurgerSideBar;
