import {
  CREATE_DISCOVERY_CONTENT_ITEM,
  DELETE_DISCOVERY_CONTENT_ITEM,
  EDIT_DISCOVERY_CONTENT_ITEM,
  GET_DISCOVERY_CONTENT_ITEM,
  HIDE_SHOW_DISCOVERY_CONTENT_ITEM
} from '../constants/UriConstants';
import {
  DiscoveryContentItemInterface,
  EditDiscoveryContentItemRequestInterface,
  DiscoveryContentItemStatusInterface,
  CreateDiscoveryContentItemRequestInterface,
  DiscoveryContentItemResponseInterface
} from '../types/DiscoveryContentItemInterface';
import apiClient from './apiClient';

export const createDiscoveryContentItem = (
  request: CreateDiscoveryContentItemRequestInterface
): Promise<DiscoveryContentItemResponseInterface> =>
  apiClient.post(CREATE_DISCOVERY_CONTENT_ITEM, request).then((response) => response.data);

export const deleteDiscoveryContentItem = async (discoveryContentID: number) => {
  await apiClient.delete(DELETE_DISCOVERY_CONTENT_ITEM + discoveryContentID);
};

export const editDiscoveryContentItem = async (request: EditDiscoveryContentItemRequestInterface): Promise<void> => {
  await apiClient.put(EDIT_DISCOVERY_CONTENT_ITEM, request);
};

export const getDiscoveryContentItems = (): Promise<DiscoveryContentItemInterface[]> =>
  apiClient.get(GET_DISCOVERY_CONTENT_ITEM).then((response) => response.data);

export const showOrHideDiscoveryContentItems = async (
  discoveryContentID: number,
  hide: boolean
): Promise<DiscoveryContentItemStatusInterface> =>
  apiClient.put(HIDE_SHOW_DISCOVERY_CONTENT_ITEM, { discoveryContentID, hide }).then((response) => response.data);
