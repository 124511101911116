import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NameCell from './NameCell';
import DeleteItemModal from '../../DeleteItemModal';
import { deleteDiscoveryContentItem } from '../../../api/discoveryContentItem';
import { useDiscoveryContentItemsContext } from '../../../contexts/DiscoveryContentItemsContext';
import { PAGE_EDIT_CONTENT } from '../../../constants/UriConstants';

const DiscoveryContentCell = ({ value }: any) => {
  const { image, discoveryContentID, name } = value;
  const [hasError, setHasError] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const { removeDiscoveryContentItem } = useDiscoveryContentItemsContext();
  const navigate = useNavigate();

  const handleEditDiscoveryContentItem = useCallback(() => {
    const path = `/${PAGE_EDIT_CONTENT}`;
    navigate(path, {
      state: {
        discoveryContentID
      }
    });
  }, [navigate, discoveryContentID]);

  const handleDeleteContent = async () => {
    try {
      await deleteDiscoveryContentItem(discoveryContentID);

      removeDiscoveryContentItem(discoveryContentID);

      // close modal
      setOpenDeleteModal(false);
      if (hasError) {
        setHasError(false);
      }
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <span className="discovery-content-name-cell">
      <DeleteItemModal
        title="DELETE CONTENT"
        message="Please confirm that you would like to delete this content."
        error={
          hasError && `An unexpected error has occurred while deleting content. Unable to perform action at this time.`
        }
        isOpen={openDeleteModal}
        onCancel={() => {
          setOpenDeleteModal(false);
          setHasError(false);
        }}
        onConfirm={handleDeleteContent}
      />
      <NameCell
        name={name}
        imageURL={image}
        itemID={discoveryContentID}
        draggable={false}
        onEdit={handleEditDiscoveryContentItem}
        onDelete={() => setOpenDeleteModal(true)}
      />
    </span>
  );
};

export default DiscoveryContentCell;
