import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface SidebarLinkProps {
  name: string;
  path: string;
  icon?: ReactNode;
  isActive?: boolean;
  onClick?: () => void;
}

const SidebarLink = ({ name, path, icon, isActive, onClick }: SidebarLinkProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <li className={`sidebar-link ${isActive ? 'selected' : ''}`}>
      <Link to={path} onClick={handleClick}>
        {icon}
        {name}
      </Link>
    </li>
  );
};

export default SidebarLink;
