import { ColDef } from 'ag-grid-community';
import ItemTable from '../ItemTable';
import DescriptionCell from '../MenuItemTable/cells/DescriptionCell';
import SingleItemCell from '../MenuItemTable/cells/SingleItemCell';
import DiscoveryContentCell from '../MenuItemTable/cells/DiscoveryContentCell';
import LoadingSpinner from '../common/LoadingSpinner';
import { useDiscoveryContentItemsContext } from '../../contexts/DiscoveryContentItemsContext';

const DiscoveryContentTable = () => {
  const { discoveryContentItems, isLoading } = useDiscoveryContentItemsContext();

  const nameCellGetter = (params: any) => ({
    image: params?.data?.media?.length > 0 ? params.data.media[0].mediaUrl : undefined,
    name: params.data.title,
    discoveryContentID: params.data.discoveryContentID,
    isHidden: params.data.hidden
  });

  const itemCellGetter = (params: any, field: string) => {
    const urls = params.data?.urls || [];
    const urlData = urls.find((url: any) => url.type === field);
    return {
      item: urlData ? urlData.url : undefined,
      isHidden: params.data?.hidden || false
    };
  };

  const isHiddenGetter = (params: any, field: string) => ({
    [field]: params.data?.[field],
    isHidden: params.data.hidden || false
  });

  const COLUMNS: ColDef[] = [
    {
      headerName: 'NAME',
      valueGetter: nameCellGetter,
      headerClass: 'name-header',
      cellRenderer: DiscoveryContentCell,
      maxWidth: 300,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      suppressMovable: true
    },
    {
      headerName: 'DESCRIPTION',
      valueGetter: (params) => isHiddenGetter(params, 'description'),
      headerClass: 'custom-header',
      cellRenderer: DescriptionCell,
      maxWidth: 190,
      suppressMovable: true,
      wrapText: true,
      autoHeight: true
    },
    {
      headerName: 'ORDERING LINKS',
      valueGetter: (params) => {
        const item = itemCellGetter(params, 'ordering');
        return {
          ...item
        };
      },
      headerClass: 'custom-header',
      maxWidth: 200,
      cellRenderer: SingleItemCell,
      suppressMovable: true
    },
    {
      headerName: 'RESERVATION LINKS',
      valueGetter: (params) => {
        const item = itemCellGetter(params, 'reservation');
        return {
          ...item
        };
      },
      headerClass: 'custom-header',
      cellRenderer: SingleItemCell,
      suppressMovable: true
    }
  ];

  if (isLoading) {
    return (
      <div className="discovery-content-page-loading">
        <LoadingSpinner />
      </div>
    );
  }

  return <ItemTable items={discoveryContentItems} columns={COLUMNS} draggable={false} />;
};

export default DiscoveryContentTable;
