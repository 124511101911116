import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorText, Input, Label, TextArea } from '../../../components/common/Form';
import Button from '../../../components/common/Button';
import SnapchatIcon from '../../../assets/svgs/icons/Socials/SnapchatIcon';
import SocialInputLabel from '../../../components/SocialsInputLabel';
import { handleHttpsInput } from '../../../utils/general';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../../types/MediaInterface';
import imageDisplay from '../../../assets/images/ImageDisplay.png';
import MediaPreview from '../../../components/MediaPreview';
import MediaLibraryModal from '../../../components/MediaLibraryModal';
import {
  GrubHub,
  Seamless,
  UberEats,
  DoorDash,
  OpenTable,
  Resy,
  Yelp,
  SevenRooms
} from '../../../assets/svgs/icons/OrderingAndReservationIcons';

interface DiscoveryContentItemPageProps {
  formik: any;
  media: MenuItemMediaInterface[];
  handleMediaUpdate: Function;
}

const DiscoveryContentItemPage = ({ formik, media, handleMediaUpdate }: DiscoveryContentItemPageProps) => {
  const [openMediaLibraryModal, setOpenMediaLibraryModal] = useState<boolean>(false);
  const [orderLinksError, setOrderLinksError] = useState<string>('');
  const [mediaError, setMediaError] = useState<string>('');
  const [reservationLinksError, setReservationLinksError] = useState<string>('');

  const navigate = useNavigate();

  const handleCancelClicked = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    setMediaError('');
  }, [media]);

  useEffect(() => {
    const orderLinksPresent = Object.values(formik.values.orderLinks).some((link) => link !== '');
    const reservationLinksPresent = Object.values(formik.values.reservationLinks).some((link) => link !== '');

    if (orderLinksPresent || reservationLinksPresent) {
      setOrderLinksError('');
      setReservationLinksError('');
    }
  }, [formik.values.orderLinks, formik.values.reservationLinks]);

  const handleMediaLibrarySelection = (selectedMedia: MediaLibraryInterface[]) => {
    const _media = media.slice();
    const newMedia = selectedMedia.slice();

    handleMediaUpdate(
      _media.concat(
        newMedia.map(
          (mediaLibrary) =>
            ({
              mediaID: mediaLibrary.mediaID,
              mediaURL: mediaLibrary.mediaUrl,
              type: mediaLibrary.type
            } as MenuItemMediaInterface)
        )
      )
    );
  };

  const handleMediaLibraryRemoval = (mediaID: number) => {
    const _media = media.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === mediaID);
    _media.splice(idx, 1);
    handleMediaUpdate(_media);
  };

  const handleNextClicked = async () => {
    formik.setTouched(
      {
        name: true,
        description: true,
        media: true,
        orderLinks: {
          grubhub: true,
          ubereats: true,
          doordash: true,
          seamless: true,
          other: true
        },
        reservationLinks: {
          opentable: true,
          resy: true,
          yelp: true,
          sevenrooms: true,
          other: true
        }
      },
      true
    );

    if (formik.values.media.length === 0) {
      setMediaError('Media is required.');
    } else if (formik.values.media.length > 10) {
      setMediaError('Maximum of 10 media items allowed.');
    } else {
      setMediaError('');
    }

    if (orderLinksError === '' && reservationLinksError === '') {
      formik.handleSubmit();
    }
  };

  const handleInputChange = (name: string, value: string) => {
    const modifiedValue = handleHttpsInput(value);
    formik.setFieldValue(name, modifiedValue);
  };

  return (
    <div className="discovery-content-item-page">
      <Input
        name="name"
        label="DISH NAME"
        className="discovery-content-wizard-name-input"
        onChange={formik.handleChange}
        value={formik.values.name}
        onBlur={formik.handleBlur}
        touched={formik.touched.name}
        error={formik.errors.name}
      />
      <TextArea
        name="description"
        label={<span>DESCRIPTION</span>}
        className="discovery-content-wizard-description-input"
        onChange={formik.handleChange}
        value={formik.values.description}
        onBlur={formik.handleBlur}
        touched={formik.touched.description}
        error={formik.errors.description}
      />
      <div className="discovery-content-wizard-image-container">
        <Label className="discovery-content-wizard-image-label" label={<span>MEDIA</span>} />
        <p className="photo-prompt">Choose up to 10 photos or video of the item.</p>
        <div className="media-container">
          <Button className="select-media-button" onClick={() => setOpenMediaLibraryModal(true)}>
            <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
            <p>Upload Media</p>
          </Button>
          <MediaPreview media={media} onUpdate={handleMediaUpdate} multiple hideEditVideo />
        </div>
        {openMediaLibraryModal && (
          <MediaLibraryModal
            selectedMediaIDs={media.map((_media) => _media.mediaID)}
            onSelect={handleMediaLibrarySelection}
            onRemove={handleMediaLibraryRemoval}
            onClose={() => setOpenMediaLibraryModal(false)}
          />
        )}
      </div>
      <ErrorText error={mediaError} className={`media-error ${mediaError ? 'show' : ''}`} />
      <Label label="ORDER LINKS" className="discovery-content-wizard-order-links-title" />
      <ErrorText error={orderLinksError} className={`order-links-error ${orderLinksError ? 'show' : ''}`} />
      <div className="discovery-content-wizard-socials-inputs">
        <Input
          name="orderLinks.grubhub"
          label={<SocialInputLabel label="grubhub" icon={<GrubHub />} />}
          className="discovery-content-wizard-socials-input"
          onChange={(event: any) => handleInputChange('orderLinks.grubhub', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.orderLinks.grubhub}
          touched={formik.touched?.orderLinks?.grubhub}
          error={formik.errors?.orderLinks?.grubhub}
          placeholder=""
        />
        <Input
          name="orderLinks.ubereats"
          label={<SocialInputLabel label="ubereats" icon={<UberEats />} />}
          className="discovery-content-wizard-socials-input"
          onChange={(event: any) => handleInputChange('orderLinks.ubereats', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.orderLinks.ubereats}
          touched={formik.touched?.orderLinks?.ubereats}
          error={formik.errors?.orderLinks?.ubereats}
          placeholder=""
        />
        <Input
          name="orderLinks.doordash"
          label={<SocialInputLabel label="doordash" icon={<DoorDash />} />}
          className="discovery-content-wizard-socials-input"
          onChange={(event: any) => handleInputChange('orderLinks.doordash', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.orderLinks.doordash}
          touched={formik.touched?.orderLinks?.doordash}
          error={formik.errors?.orderLinks?.doordash}
          placeholder=""
        />
        <Input
          name="orderLinks.seamless"
          label={<SocialInputLabel label="seamless" icon={<Seamless />} />}
          className="discovery-content-wizard-socials-input"
          onChange={(event: any) => handleInputChange('orderLinks.seamless', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.orderLinks.seamless}
          touched={formik.touched?.orderLinks?.seamless}
          error={formik.errors?.orderLinks?.seamless}
          placeholder=""
        />
        <Input
          name="orderLinks.other"
          label={<SocialInputLabel label="other" icon={<SnapchatIcon />} />}
          className="discovery-content-wizard-socials-input"
          onChange={(event: any) => handleInputChange('orderLinks.other', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.orderLinks.other}
          touched={formik.touched?.orderLinks?.other}
          error={formik.errors?.orderLinks?.other}
          placeholder=""
        />
      </div>

      {/* reservation links */}
      <Label label="RESERVATION LINKS" className="discovery-content-wizard-reservation-links-title" />
      <ErrorText error={reservationLinksError} className={`reservation-links-error ${orderLinksError ? 'show' : ''}`} />
      <div className="discovery-content-wizard-reservations-inputs">
        <Input
          name="reservationLinks.opentable"
          label={<SocialInputLabel label="opentable" icon={<OpenTable />} />}
          className="discovery-content-wizard-reservations-input"
          onChange={(event: any) => handleInputChange('reservationLinks.opentable', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.reservationLinks.opentable}
          touched={formik.touched?.reservationLinks?.opentable}
          error={formik.errors?.reservationLinks?.opentable}
          placeholder=""
        />
        <Input
          name="reservationLinks.resy"
          label={<SocialInputLabel label="resy" icon={<Resy />} />}
          className="discovery-content-wizard-reservations-input"
          onChange={(event: any) => handleInputChange('reservationLinks.resy', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.reservationLinks.resy}
          touched={formik.touched?.reservationLinks?.resy}
          error={formik.errors?.reservationLinks?.resy}
          placeholder=""
        />
        <Input
          name="reservationLinks.yelp"
          label={<SocialInputLabel label="yelp" icon={<Yelp />} />}
          className="discovery-content-wizard-reservations-input"
          onChange={(event: any) => handleInputChange('reservationLinks.yelp', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.reservationLinks.yelp}
          touched={formik.touched?.reservationLinks?.yelp}
          error={formik.errors?.reservationLinks?.yelp}
          placeholder=""
        />
        <Input
          name="reservationLinks.sevenrooms"
          label={<SocialInputLabel label="sevenrooms" icon={<SevenRooms />} />}
          className="discovery-content-wizard-reservations-input"
          onChange={(event: any) => handleInputChange('reservationLinks.sevenrooms', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.reservationLinks.sevenrooms}
          touched={formik.touched?.reservationLinks?.sevenrooms}
          error={formik.errors?.reservationLinks?.sevenrooms}
          placeholder=""
        />
        <Input
          name="reservationLinks.other"
          label={<SocialInputLabel label="other" icon={<SnapchatIcon />} />}
          className="discovery-content-wizard-reservations-input"
          onChange={(event: any) => handleInputChange('reservationLinks.other', event.target.value)}
          onBlur={formik.handleBlur}
          value={formik.values.reservationLinks.other}
          touched={formik.touched?.reservationLinks?.other}
          error={formik.errors?.reservationLinks?.other}
          placeholder=""
        />
      </div>
      <div className="discovery-content-wizard-button-container">
        <Button className="discovery-content-wizard-cancel-button" onClick={handleCancelClicked}>
          CANCEL
        </Button>
        <Button className="discovery-content-wizard-next-button" onClick={handleNextClicked}>
          CONFIRM
        </Button>
      </div>
    </div>
  );
};

export default DiscoveryContentItemPage;
