import React, { useState, KeyboardEvent } from 'react';
import { useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import BurgerIcon from '../../assets/svgs/icons/BurgerIcon/BurgerIcon';
import Logo from '../../assets/images/TapTabLogoBlackSmallWithName.png';
import {
  PAGE_CREATE_DRINK_ITEM,
  PAGE_CREATE_MENU,
  PAGE_CREATE_MENU_ITEM,
  PAGE_CREATE_MODIFIER,
  PAGE_CREATE_RESTAURANT,
  PAGE_EDIT_DRINK_ITEM,
  PAGE_EDIT_MENU,
  PAGE_EDIT_MENU_ITEM,
  PAGE_EDIT_MODIFIER,
  PAGE_EDIT_RESTAURANT
} from '../../constants/UriConstants';
import 'react-loading-skeleton/dist/skeleton.css';
import { RestaurantDetailsInterface } from '../../types/RestaurantInterface';
import { useRestaurantContext } from '../../contexts/RestaurantContext';
import { Dropdown } from '../common/Form';
import MobileBurgerSidebar from '../MobileBurgerSideBar/MobileBurgerSideBar';
import { useMenuContext } from '../../contexts/MenuContext';

const MobileNavBar = () => {
  const { pathname } = useLocation();
  const { currentRestaurant, setRestaurant, restaurants } = useRestaurantContext();
  const { resetMenu } = useMenuContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleRestaurantSelected = (restaurantID: string) => {
    const selected = restaurants?.find((restaurant) => restaurant.restaurantID === parseInt(restaurantID, 10));
    resetMenu();
    setRestaurant(selected?.restaurantID);
  };

  const getRestaurantName = (selectedRestaurant: RestaurantDetailsInterface) => {
    if (restaurants?.length === 0) {
      return null;
    }

    if (!selectedRestaurant) {
      return <Skeleton width="157px" />;
    }
    if (
      restaurants?.length > 1 &&
      !(
        pathname.includes(PAGE_CREATE_MODIFIER) ||
        pathname.includes(PAGE_EDIT_MODIFIER) ||
        pathname.includes(PAGE_CREATE_DRINK_ITEM) ||
        pathname.includes(PAGE_EDIT_DRINK_ITEM) ||
        pathname.includes(PAGE_CREATE_MENU) ||
        pathname.includes(PAGE_EDIT_MENU) ||
        pathname.includes(PAGE_CREATE_MENU_ITEM) ||
        pathname.includes(PAGE_EDIT_MENU_ITEM) ||
        pathname.includes(PAGE_CREATE_RESTAURANT) ||
        pathname.includes(PAGE_EDIT_RESTAURANT)
      )
    ) {
      return (
        <Dropdown
          className="mobile-navbar-dropdown"
          onChange={handleRestaurantSelected}
          value={currentRestaurant.restaurantID}
          values={restaurants?.map((restaurant) => ({ label: restaurant.name, value: restaurant.restaurantID }))}
        />
      );
    }
    return <div className="sidebar-restaurant-name">{selectedRestaurant.name}</div>;
  };

  const handleSidebarOpen = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSidebarOpen();
    }
  };

  return (
    <div className="mobile-navbar-container">
      <div className="mobile-navbar-logo">
        <img src={Logo} alt="logo" />
      </div>
      <div className="mobile-navbar-dropdown-container">{getRestaurantName(currentRestaurant)}</div>
      <div
        className="mobile-navbar-burger"
        onClick={handleSidebarOpen}
        onKeyPress={handleKeyPress}
        role="button"
        tabIndex={0}
        aria-label="Open sidebar"
      >
        <BurgerIcon />
      </div>
      {isSidebarOpen && <MobileBurgerSidebar isVisible={isSidebarOpen} onClose={handleCloseSidebar} />}
    </div>
  );
};

export default MobileNavBar;
