import { useEffect } from 'react';
import { useDiscoveryContentItemsContext } from '../../../contexts/DiscoveryContentItemsContext';
import DiscoveryContentTopBar from '../../../components/DiscoveryContentTopBar';
import DiscoveryContentTable from '../../../components/DiscoveryContentTable';

const DiscoveryContentPage = () => {
  const { loadDiscoveryContentItems } = useDiscoveryContentItemsContext();
 
  useEffect(() => {
    // only want to load the discovery content items if Discovery Content page is being utilized
    // call to load those Discovery Content Items on initial load
    loadDiscoveryContentItems();
  }, [loadDiscoveryContentItems]);

  return (
    <div className="discovery-content-page-container">
      <DiscoveryContentTopBar />
      <DiscoveryContentTable />
    </div>
  );
};

export default DiscoveryContentPage;
