import { createContext, ReactElement, useMemo, useContext, useState, useEffect, useCallback } from 'react';
import { DiscoveryContentItemInterface } from '../../types/DiscoveryContentItemInterface';
import { getDiscoveryContentItems } from '../../api/discoveryContentItem';
import { useRestaurantContext } from '../RestaurantContext';

interface DiscoveryContentItemsContextInterface {
  hasError: boolean;
  isLoading: boolean;
  discoveryContentItems: DiscoveryContentItemInterface[];
  loadDiscoveryContentItems: Function;
  addDiscoveryContentItem: Function;
  getDiscoveryContentItem: Function;
  removeDiscoveryContentItem: Function;
  updateDiscoveryContentItem: Function;
}

interface DiscoveryContentItemsProviderInterface {
  children: ReactElement;
}

const DiscoveryContentItemsContext = createContext<DiscoveryContentItemsContextInterface>({
  hasError: false,
  isLoading: true,
  discoveryContentItems: null,
  loadDiscoveryContentItems: () => {},
  addDiscoveryContentItem: () => {},
  getDiscoveryContentItem: () => {},
  removeDiscoveryContentItem: () => {},
  updateDiscoveryContentItem: () => {}
});

const DiscoveryContentItemsProvider = ({ children }: DiscoveryContentItemsProviderInterface) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(false);
  const [discoveryContentItems, setDiscoveryContentItems] = useState<DiscoveryContentItemInterface[]>(null);
  const [currentRestaurantID, setCurrentRestaurantID] = useState<number>(-1);

  const { currentRestaurant } = useRestaurantContext();

  useEffect(() => {
    let canceled = false;

    if (initialLoad && currentRestaurant && currentRestaurant.restaurantID !== currentRestaurantID) {
      setIsLoading(true);

      getDiscoveryContentItems()
        .then((_discoveryContentItems: DiscoveryContentItemInterface[]) => {
          if (canceled) return;
          setDiscoveryContentItems(_discoveryContentItems);
          setCurrentRestaurantID(currentRestaurant.restaurantID);
          setIsLoading(false);
        })
        .catch((error) => {
          if (canceled) return;
          if (error.response.status !== 401) {
            // on non auth related failure indicate failure has occurred
            setHasError(true);
          }
          setIsLoading(false);
        });
    }

    return () => {
      canceled = true;
    };
  }, [initialLoad, discoveryContentItems, currentRestaurant, currentRestaurantID]);

  const loadDiscoveryContentItems = useCallback(() => {
    if (!initialLoad && discoveryContentItems == null) {
      setInitialLoad(true);
    }
  }, [initialLoad, discoveryContentItems]);

  const addDiscoveryContentItem = useCallback(
    (discoveryContentItem: DiscoveryContentItemInterface) => {
      const _discoveryContentItems = discoveryContentItems.slice();
      _discoveryContentItems.push({ ...discoveryContentItem } as DiscoveryContentItemInterface);
      setDiscoveryContentItems(_discoveryContentItems);
    },
    [discoveryContentItems]
  );

  const getDiscoveryContentItem = useCallback(
    (discoveryContentID: number): DiscoveryContentItemInterface =>
      discoveryContentItems.find(
        (discoveryContentItem) => discoveryContentItem.discoveryContentID === discoveryContentID
      ),
    [discoveryContentItems]
  );

  const removeDiscoveryContentItem = useCallback(
    (discoveryContentID: number) => {
      const _discoveryContentItems = discoveryContentItems.slice();
      const discoveryContentItemIndex: number = _discoveryContentItems.findIndex(
        (section) => section.discoveryContentID === discoveryContentID
      );
      _discoveryContentItems.splice(discoveryContentItemIndex, 1);
      setDiscoveryContentItems(_discoveryContentItems);
    },
    [discoveryContentItems]
  );

  const updateDiscoveryContentItem = useCallback(
    (discoveryContentItem: DiscoveryContentItemInterface) => {
      const _discoveryContent = discoveryContentItems.slice();
      const discoveryContentIndex: number = _discoveryContent.findIndex(
        (item) => item.discoveryContentID === discoveryContentItem.discoveryContentID
      );

      _discoveryContent.splice(discoveryContentIndex, 1, {
        ...discoveryContentItems[discoveryContentIndex],
        ...discoveryContentItem
      });
      setDiscoveryContentItems(_discoveryContent);
    },
    [discoveryContentItems]
  );

  const providerValue = useMemo(
    () => ({
      hasError,
      isLoading,
      discoveryContentItems,
      loadDiscoveryContentItems,
      addDiscoveryContentItem,
      getDiscoveryContentItem,
      removeDiscoveryContentItem,
      updateDiscoveryContentItem
    }),
    [
      hasError,
      isLoading,
      discoveryContentItems,
      loadDiscoveryContentItems,
      addDiscoveryContentItem,
      getDiscoveryContentItem,
      removeDiscoveryContentItem,
      updateDiscoveryContentItem
    ]
  );
  return (
    <DiscoveryContentItemsContext.Provider value={providerValue}>{children}</DiscoveryContentItemsContext.Provider>
  );
};

const useDiscoveryContentItemsContext = () => {
  const context = useContext(DiscoveryContentItemsContext);
  if (!context) {
    throw new Error(`DiscoveryContentItemsContext must be used within the DiscoveryContentItemsProvider component`);
  }
  return context;
};

export { DiscoveryContentItemsProvider as default, useDiscoveryContentItemsContext };
