import React from 'react';
import imageDisplay from '../../assets/images/ImageDisplay.png';
import { validateMediaUpload } from '../../utils/media';
import { MediaLibraryInterface } from '../../types/MediaInterface';
import { uploadMediaImages, uploadMediaVideos } from '../../api/media';
import { displayLoading, hideLoading, useAsyncContext } from '../../contexts/AsyncContext';
import { openModal, useModalContext } from '../../contexts/ModalContext';

interface MediaLibraryUploaderProps {
  mediaType: 'image' | 'video' | 'all';
  onUpload: Function;
}

const MediaLibraryUploader = ({ mediaType, onUpload }: MediaLibraryUploaderProps) => {
  const MEDIA_MIME_TYPES = {
    IMAGE: 'image/.jpg,.jpeg,.png,.gif,.webp',
    VIDEO: 'video/.mp4,.MP4,.mov,.MOV,.webm',
    ALL: 'image/.jpg,.jpeg,.png,.gif,.webp,video/.mp4,.MP4,.mov,.MOV,.webm'
  };
  const getAcceptType = (): string =>
    // @ts-ignore
    MEDIA_MIME_TYPES[mediaType.toUpperCase()];

  const { dispatch: asyncDispatch } = useAsyncContext();
  const { dispatch: modalDispatch } = useModalContext();

  const handleMediaUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList = event?.target?.files;

    try {
      if (await validateMediaUpload(fileList)) {
        const images: File[] = [];
        const videos: File[] = [];

        for (let i = 0; i < fileList?.length; i++) {
          if (fileList?.item(i).type?.includes('image')) {
            images.push(fileList?.item(i));
          } else if (fileList?.item(i).type?.includes('video')) {
            videos.push(fileList?.item(i));
          }
        }

        const uploadedMedia: MediaLibraryInterface[] = [];
        displayLoading({ dispatch: asyncDispatch, message: 'Uploading Media...' });
        if (images.length > 0) {
          const uploadedImages = await uploadMediaImages(images);
          if (uploadedImages?.length > 0) {
            uploadedMedia.push(...uploadedImages);
          }
        }

        if (videos.length > 0) {
          const uploadedVideos = await uploadMediaVideos(videos);
          if (uploadedVideos?.length > 0) {
            uploadedMedia.push(...uploadedVideos);
          }
        }

        onUpload(uploadedMedia);
      }
    } catch (err) {
      let message = 'An unexpected error has occurred. Please check your selected files and try again.';

      if (!err.response) {
        message = err.message;
      }

      openModal({
        dispatch: modalDispatch,
        message
      });
    } finally {
      hideLoading(asyncDispatch);
    }
  };

  return (
    <label htmlFor="mediaLibraryUploader">
      <div className="media-library-upload-container">
        <img className="media-upload-icon" src={imageDisplay} alt="Icon indicating upload" />
        <p>Upload Media or Drag and Drop from computer</p>
        <input
          id="mediaLibraryUploader"
          type="file"
          accept={getAcceptType()}
          multiple
          onChange={handleMediaUpload}
          onClick={(event) => {
            // Allows for the same image to be uploaded multiple times (e.g. any time a failure occurs)
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            event.target.value = null;
          }}
        />
      </div>
    </label>
  );
};

export default MediaLibraryUploader;
