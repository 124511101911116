import React, { useState } from 'react';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  TouchSensor,
  MouseSensor,
  ScreenReaderInstructions,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import MediaSelectionPreview from './MediaSelectionPreview';
import { ErrorText } from '../common/Form';
import { MenuItemMediaInterface } from '../../types/MediaInterface';

interface MediaPreviewProps {
  media: MenuItemMediaInterface[];
  onUpdate: Function;
  onEditVideo?: Function;
  index?: number | string;
  multiple?: boolean;
  // adding this hide edit as a quick implementation to hide edit functionality for videos that do not need thumbnail
  // look to PR: https://github.com/taptabapp/TapManager/pull/622
  hideEditVideo?: boolean;
}

const MediaPreview = ({ media, onEditVideo, onUpdate, multiple = true, index = 0, hideEditVideo = false }: MediaPreviewProps) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleDeletePreview = (idOfMedia: number) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    const _media: MenuItemMediaInterface[] = media.slice();
    const idx = _media.findIndex((mediaToDelete) => mediaToDelete?.mediaID === idOfMedia);
    _media.splice(idx, 1);
    onUpdate?.(_media);
  };

  const handleEditPreview = (idOfMedia: number) => {
    if (errorMessage) {
      setErrorMessage('');
    }

    onEditVideo?.(idOfMedia);
  };

  const handleMediaReorder = async ({ active, over }: DragEndEvent) => {
    if (!over) {
      return;
    }

    if (media?.length < 2) {
      return;
    }

    if (errorMessage) {
      setErrorMessage('');
    }

    const oldIndex = media.findIndex((_media) => _media.mediaID.toString() === active.id);
    const newIndex = media.findIndex((_media) => _media.mediaID.toString() === over.id);

    const reorderedMedia = arrayMove(media, oldIndex, newIndex);
    onUpdate?.(reorderedMedia);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 2
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const screenReaderInstructions: ScreenReaderInstructions = {
    draggable: `
    To pick up a sortable item, press the space bar.
    While sorting, use the arrow keys to move the item.
    Press space again to drop the item in its new position, or press escape to cancel.
  `
  };

  return (
    <div className="multiple-media-uploader-container">
      <div className="multiple-media-uploader">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          screenReaderInstructions={screenReaderInstructions}
          onDragEnd={handleMediaReorder}
          modifiers={[restrictToHorizontalAxis, restrictToWindowEdges]}
        >
          {media && (
            <div className="multiple-media-drag-and-drop">
              <SortableContext
                strategy={horizontalListSortingStrategy}
                items={media?.map((_item) => _item.mediaID?.toString())}
              >
                {media?.map((_media) => (
                  <MediaSelectionPreview
                    key={_media.mediaID}
                    id={multiple ? _media.mediaID : index}
                    media={_media}
                    multiple={multiple}
                    onDelete={handleDeletePreview}
                    onEdit={handleEditPreview}
                    thumbnail={
                      _media?.thumbnail && Object.keys(_media?.thumbnail)?.length > 0 ? _media?.thumbnail : undefined
                    }
                    hideEdit={hideEditVideo}
                  />
                ))}
              </SortableContext>
            </div>
          )}
        </DndContext>
      </div>
      <ErrorText
        className={`media-upload-error-message ${errorMessage?.length > 0 ? 'has-error' : ''}`}
        error={errorMessage}
      />
    </div>
  );
};

export default MediaPreview;
