import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGE_CREATE_CONTENT } from '../../constants/UriConstants';
import Button from '../common/Button';

const DiscoveryContentTopBar = () => {
  const navigate = useNavigate();
  const handleAddContent = useCallback(() => {
    navigate(`/${PAGE_CREATE_CONTENT}`);
  }, [navigate]);

  return (
    <div className="discovery-content-top-bar-container shadow-[0_0_20px_6px_rgb(0,0,0,0.1)]">
      <Button className="add-content-button" onClick={handleAddContent}>
        ADD CONTENT
      </Button>
    </div>
  );
};

export default DiscoveryContentTopBar;
