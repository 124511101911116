import React, { useCallback, useEffect } from 'react';
import { array, InferType, number, object, string } from 'yup';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import DiscoveryContentItemPage from './DiscoveryContentItemPage';
import WizardHeader from '../../../components/WizardHeader';
import { MediaLibraryInterface, MenuItemMediaInterface } from '../../../types/MediaInterface';
import {
  CreateDiscoveryContentItemRequestInterface,
  DiscoveryContentItemInterface,
  DiscoveryContentMedia,
  DiscoveryContentUrls,
  EditDiscoveryContentItemRequestInterface
} from '../../../types/DiscoveryContentItemInterface';
import { useMediaLibraryContext } from '../../../contexts/MediaLibraryContext';
import { displayLoading, hideLoading, useAsyncContext } from '../../../contexts/AsyncContext';
import { useDiscoveryContentItemsContext } from '../../../contexts/DiscoveryContentItemsContext';
import { createDiscoveryContentItem, editDiscoveryContentItem } from '../../../api/discoveryContentItem';
import { openModal } from '../../../contexts/ModalContext';
import { PAGE_DISCOVERY_CONTENT } from '../../../constants/UriConstants';

export const OrderLinksSchema = object({
  grubhub: string().default(''),
  ubereats: string().default(''),
  doordash: string().default(''),
  seamless: string().default(''),
  other: string().default('')
});

export const ReservationLinksSchema = object({
  opentable: string().default(''),
  yelp: string().default(''),
  resy: string().default(''),
  sevenrooms: string().default(''),
  other: string().default('')
});

export const DiscoveryContentItemSchema = object({
  name: string().default('').required('Name is required.'),
  description: string().default(''),
  media: array()
    .min(1, 'Media is required.')
    .max(10, 'Media cannot exceed 10 items.')
    .of(
      object({
        mediaID: number(),
        mediaURL: string(),
        type: string(),
        thumbnail: object({
          thumbnailID: string(),
          thumbnailURL: string()
        })
      })
    )
    .required('Media is required.'),
  orderLinks: OrderLinksSchema,
  reservationLinks: ReservationLinksSchema
});

interface DiscoveryContentWizardNavState {
  discoveryContentID: number;
}

const DiscoveryContentWizard = () => {
  const { state } = useLocation();
  const { discoveryContentID } = (state as DiscoveryContentWizardNavState) || {};
  const { findMediaByID, loadMedia } = useMediaLibraryContext();
  const { dispatch } = useAsyncContext();
  const { addDiscoveryContentItem, getDiscoveryContentItem, updateDiscoveryContentItem } =
    useDiscoveryContentItemsContext();

  const isEditItem = !!discoveryContentID;

  useEffect(() => {
    // only want to load the restaurants user media if manager page is being utilized
    // call to load media on initial load
    loadMedia();
  }, [loadMedia]);

  const navigate = useNavigate();

  const getInitialValues = useCallback(
    (isEdit) => {
      const platformMapping = {
        grub_hub: 'grubhub',
        uber_eats: 'ubereats',
        door_dash: 'doordash',
        seamless: 'seamless',
        open_table: 'opentable',
        yelp: 'yelp',
        resy: 'resy',
        seven_rooms: 'sevenrooms',
        other: 'other'
      };

      const mapBackendToFrontendPlatform = (platform: string): string => {
        if (platform in platformMapping) {
          return platformMapping[platform as keyof typeof platformMapping];
        }
        return platform;
      };
      if (isEdit) {
        const discoveryContent: DiscoveryContentItemInterface = getDiscoveryContentItem(discoveryContentID);
        return {
          name: discoveryContent?.title,
          description: discoveryContent?.description || '',
          media: discoveryContent?.media.map((_media: DiscoveryContentMedia) => ({
            ..._media,
            type: _media.mediaType,
            mediaURL: _media.mediaUrl
          })),
          orderLinks: {
            grubhub:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'grubhub'
              )?.url ?? '',
            ubereats:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'ubereats'
              )?.url ?? '',
            doordash:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'doordash'
              )?.url ?? '',
            seamless:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'seamless'
              )?.url ?? '',
            other:
              discoveryContent?.urls?.find(
                (url) =>
                  mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'other' &&
                  url?.type === 'ordering'
              )?.url ?? ''
          },
          reservationLinks: {
            opentable:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'opentable'
              )?.url ?? '',
            yelp:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'yelp'
              )?.url ?? '',
            resy:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'resy'
              )?.url ?? '',
            sevenrooms:
              discoveryContent?.urls?.find(
                (url) => mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'sevenrooms'
              )?.url ?? '',
            other:
              discoveryContent?.urls?.find(
                (url) =>
                  mapBackendToFrontendPlatform(url?.platform as keyof typeof platformMapping) === 'other' &&
                  url?.type === 'reservation'
              )?.url ?? ''
          }
        };
      }
      return DiscoveryContentItemSchema.cast({
        name: '',
        description: '',
        media: [],
        orderLinks: {
          grubhub: '',
          ubereats: '',
          doordash: '',
          seamless: '',
          other: ''
        },
        reservationLinks: {
          opentable: '',
          yelp: '',
          resy: '',
          sevenrooms: '',
          other: ''
        }
      });
    },
    [getDiscoveryContentItem, discoveryContentID]
  );

  const handleOnSubmit = async (value: InferType<typeof DiscoveryContentItemSchema>) => {
    const urlCombiner = (
      orderLinks: InferType<typeof OrderLinksSchema>,
      reservationLinks: InferType<typeof ReservationLinksSchema>
    ): DiscoveryContentUrls[] => {
      const urls: DiscoveryContentUrls[] = [];

      Object.entries(orderLinks).forEach(([key, values]) => {
        if (values) {
          urls.push({ url: values, platform: key, type: 'ordering' } as DiscoveryContentUrls);
        }
      });

      Object.entries(reservationLinks).forEach(([resKey, resValue]) => {
        if (resValue) {
          urls.push({ url: resValue, platform: resKey, type: 'reservation' } as DiscoveryContentUrls);
        }
      });

      return urls;
    };

    if (DiscoveryContentItemSchema.isValidSync(value)) {
      displayLoading({ dispatch, message: `${isEditItem ? 'Updating' : 'Creating'} Content...` });
      if (isEditItem) {
        const mediaIDs: number[] = value.media.map((item) => item.mediaID);
        const editDiscoveryContentItemRequest: EditDiscoveryContentItemRequestInterface = {
          discoveryContentID,
          title: value.name.trim(),
          description: value.description.trim(),
          mediaIDs,
          urls: urlCombiner(value.orderLinks, value.reservationLinks)
        };

        try {
          await editDiscoveryContentItem(editDiscoveryContentItemRequest);
          const updatedMedia: MediaLibraryInterface[] = findMediaByID(mediaIDs);
          const updatedDiscoveryContentItem: DiscoveryContentItemInterface = {
            discoveryContentID,
            title: editDiscoveryContentItemRequest.title.trim(),
            description: editDiscoveryContentItemRequest.description.trim(),
            media: updatedMedia.map(
              (_media: MediaLibraryInterface) =>
                ({
                  mediaID: _media.mediaID,
                  mediaUrl: _media.mediaUrl,
                  mediaType: _media.type
                } as DiscoveryContentMedia)
            ),
            urls: editDiscoveryContentItemRequest.urls
          };
          updateDiscoveryContentItem(updatedDiscoveryContentItem);

          hideLoading(dispatch);
          toast.success(`Successfully ${isEditItem ? 'Updated' : 'Created'} Discovery Content!`, {
            position: toast.POSITION.TOP_RIGHT
          });
          navigate(`/${PAGE_DISCOVERY_CONTENT}`);
        } catch (error) {
          console.error(error);
          const errorMessage = `Unexpected error occurred while updating content. Check your input and try again. If this issue keeps occurring please reach out to the TapTab team.`;
          hideLoading(dispatch);
          openModal({
            dispatch,
            title: `Error occurred while updating content.`,
            message: errorMessage
          });
        }
      } else {
        const createDiscoveryContentItemRequest: CreateDiscoveryContentItemRequestInterface = {
          title: value.name,
          description: value.description,
          mediaIDs: value.media.map((item) => item.mediaID),
          urls: urlCombiner(value.orderLinks, value.reservationLinks)
        };

        try {
          const createdDiscoveryContentItem = await createDiscoveryContentItem(createDiscoveryContentItemRequest);
          addDiscoveryContentItem({ ...createdDiscoveryContentItem });

          hideLoading(dispatch);
          toast.success(`Successfully ${isEditItem ? 'Updated' : 'Created'} Discovery Content!`, {
            position: toast.POSITION.TOP_RIGHT
          });

          navigate(`/${PAGE_DISCOVERY_CONTENT}`);
        } catch (error) {
          console.error(error);
          const errorMessage = `Unexpected error occurred while creating content. Check your input and try again. If this issue keeps occurring please reach out to the TapTab team.`;

          hideLoading(dispatch);
          openModal({
            dispatch,
            title: `Error occurred while creating content.`,
            message: errorMessage
          });
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: getInitialValues(isEditItem),
    validationSchema: DiscoveryContentItemSchema,
    enableReinitialize: true,
    onSubmit: handleOnSubmit
  });

  const handleMediaUpdate = (mediaInvolved: MenuItemMediaInterface[]) => {
    formik.setFieldValue('media', mediaInvolved);
  };

  return (
    <div className="discovery-content-wizard-container">
      <WizardHeader className="discovery-content-wizard-header">
        <div className="discovery-content-wizard-header-content">
          <h1 className="discovery-content-wizard-header-title">{isEditItem ? 'EDIT' : 'ADD'} CONTENT</h1>
        </div>
      </WizardHeader>
      <form className="discovery-content-wizard-content" onSubmit={formik.handleSubmit}>
        <DiscoveryContentItemPage
          formik={formik}
          media={formik.values.media as MenuItemMediaInterface[]}
          handleMediaUpdate={handleMediaUpdate}
        />
      </form>
    </div>
  );
};

export default DiscoveryContentWizard;
